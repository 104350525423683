@use "./variables" as *;
@use "./mixins" as *;

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    // font-family: 'manrope',Arial, Helvetica, sans-serif;
    scroll-behavior: smooth;
    // border: 1px solid red;
}
.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
    padding: .5rem;

    &_content {
        position: relative;
        background-color: #000000;
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    &_close {
        color: #aaa;
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 28px;
        font-weight: bold;
    }

    &_close:hover,
    &_close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

    &_header{
        margin: 2rem 0rem 1rem 0rem;
        

        &-container{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        &-description{
            margin: 1rem 0;

            &-container{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        }
    }
}


.topFive{

    &_card{
        background-color: rgb(40, 40, 40);
        background-image: url(../../Assets/the-6mix-logo.png);
        background-repeat: repeat;
        background-size: 4rem;
        margin: 2rem auto;
        padding: 1rem;
        // width: 90%;
        max-width: 35rem;
        border-radius: 4px;
    }

    &_image{
        height: 6rem;
        border-radius: 4px;

        &-container{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }


    &_outer-container{
        background-color: rgb(52, 51, 51);
        padding: 1rem;
        margin: 1rem 0;
        border-radius: 4px;
        
    }

    &_header{

        &-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
        &-heading{
            font-weight: 800;
        }

    }
    
    

    &_container{
        background-color: rgb(75, 75, 75);
        margin: .5rem 0;
        padding: .5rem;
        display: flex;
        flex-direction: row;
        border-radius: 4px;

        &-header{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 1rem auto;
        }

        &-image{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 .5rem;
        }

        &-number{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
        }

        &-info{
            width: 70%;
        }
    }

    &_list{

    &-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        
    }

    &-number{
        font-size: 1.8rem;
        margin-right: .5rem;
    }

    &-name{
        font-size: .9rem;
        font-weight: 800;
        margin: 0 0 1rem .8rem;
    }

    &-artist{
        font-size: .8rem;
        margin: 0 0 0 .8rem
    }

    }

    &_button{
        background-color: rgb(5, 240, 37);
        height: 2rem;
        color: black;
        border: none !important;
        font-size: .8rem;
        font-weight: 800;
        border-radius: 50px;
        padding: 1rem 2rem;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        margin-bottom: 2rem;
        &:hover{
            background-color: #01b82e;
        }

        &-container{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 3rem;
        }
    }

    &_closing-caption{
        font-size: 8px;

        &-container{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
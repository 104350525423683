.playlistButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_header{
        margin: 1rem 0;
    }


    &_button{
    height: 2rem;
    background-color: #06f040;
    padding: 0 2rem;
    color: black;
    border-radius: 60px;
    border: none;
    font-size: 18px;
    font-weight: 800;
    margin: 1rem 0;
    &:hover{
        background-color: #01b82e;
    }
}
}
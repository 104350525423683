.welcome{
    color: white;
    background-color: rgb(44, 44, 44);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 35rem;
    margin: auto;
    padding: 1rem;

    &_image{
        height: 8rem;
        margin: 1rem 0;
        border-radius: 4px;
    }

    &_header{
        margin: 1rem 0;
    }
    &_description{
        margin-bottom: 1rem;
    }
}